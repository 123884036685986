.image-copyright-footer {
    position: absolute;
    z-index: 5;
    right: 8px; bottom: 8px; left: 8px;

    span {
        &.icon {
            position: absolute;
            bottom: 0; left: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            background-color: $color__white;
            border-radius: 50%;
            transition: background-color .2s linear;

            &:hover {
                //background-color: $color__primary--hover;
                cursor: help;

                img {
                    //filter: $filter__white;
                }

                + span {
                    opacity: 1;
                }
            }

            &::before {
                content: "";
                position: absolute;
                z-index: -1;
                top: -8px; left: -8px;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                pointer-events: auto;
            }

            img {
                filter: $filter__font;
                transition: filter .15s linear;
            }
        }

        &:not(.icon) {
            position: absolute;
            bottom: 44px; left: 0;
            padding: 8px;
            font-size: 14px;
            font-weight: $font__weight--normal;
            line-height: 1.25;
            max-width: 100%;
            color: $color__white;
            background-color: $color__font;
            border-radius: 4px;
            opacity: 0;
            pointer-events: none;
            transition: opacity .2s linear;

            &::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 16px;
                border: 8px solid transparent;
                border-top-color: $color__font;
                border-bottom-width: 0;
                pointer-events: none;
                transform: translateX(-50%);
            }
        }
    }
}
